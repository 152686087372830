import { IMedia } from '@models/company/company';
import { WaypointType } from '@models/waypoint/waypoint-type';

export interface Waypoint {
  id?: string;
  type: WaypointType;
  nomenclature: WaypointType;
  label: string;
  description?: string;
  userCount?: number;
  level?: number;
  final?: boolean;
  isNomenclature?: boolean;
  codeRome?: string;
  salaryMin?: number;
  salaryMax?: number;
  isMoreDiplomaOrJob?: boolean;
  bridgeType?: BridgeTypeEnum;
  humanMedias?: IMedia[];
  isAppOwnership?: boolean;

  /**
   * Whether the job is recruiting and in tension
   * Used in Region SUD and CMQ gps
   */
  trends?: Trends;

  /**
   * The Onisep id of the job
   * Used in the Region Sud gps roadmap
   */
  codeOnisep?: string;

  /**
   * Number of companies available for the waypoint
   */
  locationCount?: number;

  // FIXME : Temporary hack to add waypoint domain for itineraries
  domainWaypoint?: Waypoint;

  /**
   * An other waypoint closely related to this one: eg the diploma related to a diploma major
   */
  relatedWaypoint?: Waypoint;
}

export interface Trends {
  recruiting: boolean;
  inTension: boolean;
}

export interface Domain extends Waypoint {
  waypointCount: number;
  waypoints: Waypoint[];
}

export interface DiplomaType extends Waypoint {
  domainCount: number;
  domains: Domain[];
}

export interface Choice {
  type: WaypointType;
  userCount: number;
}

export interface ChoiceDomain extends Choice {
  domains: Domain[];
  domainCount: number;
  locationCount?: number; // Number of companies available for the domain
}

export interface ChoiceDiplomaType extends Choice {
  diplomaTypes: DiplomaType[];
  diplomaTypeCount: number;
}

export interface ExpandDiplomaType {
  type: ExpandTypeEnum.DiplomaType;
  diplomas: ChoiceDiplomaType;
  jobs: ChoiceDiplomaType;
  bridgeDuring?: ChoiceDiplomaType;
  bridgeAfter?: ChoiceDiplomaType;
}

export interface ExpandDomain {
  type: ExpandTypeEnum.Domain;
  diplomas: ChoiceDomain;
  jobs: ChoiceDomain;
}

export enum ExpandTypeEnum {
  Domain = 'domain',
  DiplomaType = 'diplomaType'
}

export enum BridgeTypeEnum {
  BridgeDuring = 'bridgeDuring',
  BridgeAfter = 'bridgeAfter'
}

export type Expand = ExpandDomain | ExpandDiplomaType;

// -- FUNCTIONS --

export const isDiploma = (waypointType: WaypointType): boolean =>
  waypointType === WaypointType.diploma;

export const isDiplomaMajor = (waypointType: WaypointType): boolean =>
  waypointType === WaypointType.diplomaMajor;

export const isJob = (waypointType: WaypointType): boolean => waypointType === WaypointType.job;
export const getWaypointType = (waypointType: WaypointType): WaypointType =>
  isDiploma(waypointType) ? WaypointType.diploma : waypointType;

export const domain = (waypoints: Waypoint[]): Waypoint =>
  waypoints.find(wp => WaypointType.domain === wp.nomenclature);

export const middleSchoolId = '81a2544c-821d-4aa7-a65f-91c1d513c491';
export const middleSchool2ndGenTechId = 'c98030d7-8a61-473d-b8bb-1a1e9eee224c';
export const highSchoolId = '51459ee4-7a9f-43af-aacc-15ba7a3d7261';
