import { Injectable } from '@angular/core';
import { highSchoolTypesUuids, HighSchoolType } from '@clients/adventure/bean/situation';
import { DataClient } from '@core/services';
import { SelectMultiCardOption } from '@models/select-multi-card-option/select-multi-card-option';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DiplomaTecthResolver {
  constructor(private dataClient: DataClient) {}

  public resolve(): Observable<SelectMultiCardOption[]> {
    return this.dataClient
      .getDiplomasNameWithDiplomaType(highSchoolTypesUuids(HighSchoolType.TECH))
      .pipe(
        map(data =>
          Object.values(data.diplomasName).map((bacSpecialtyName, index) => ({
            value: data.diplomasValue[index],
            label: bacSpecialtyName
          }))
        )
      );
  }
}
