// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';
import { Tag } from '@clients/adventure/bean/tag';
import { getWaypointType } from '@clients/adventure/bean/waypoint';
import { Definition } from '@models/definition/definition';
import { Filters } from '@models/filters/filters';
import { Gender } from '@models/gender/gender';
import { WaypointType } from '@models/waypoint/waypoint-type';
import { filter, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';
import { DefinitionClient, TagClient } from '../clients';

export class DefinitionByTag {
  public tagLabel = '';
  public definitions = new Array<Definition>();
}


@Injectable({ providedIn: 'root' })
export class DefinitionService {
  private tagsCache: Tag[];
  private tagsType: WaypointType;
  private getDefinitionsByTagsCache: { [key: string]: Definition[] } = {};

  constructor(private client: DefinitionClient, private tagClient: TagClient) {}

  /**
   * Get a definition from an id
   *
   * @param definitionId The definition id
   * @returns The matching definition
   */
  public getDefinition(definitionId: string): Observable<Definition> {
    return this.client.getDefinition(definitionId);
  }

  /**
   * Get all enabled and FROM_ADMIN definitions by the application, status and tag id
   *
   * @param definitionFilters Filters
   * @param waypointType The waypoint type
   * @param tagId The tag id. If not provided, get definitions without tag
   * @returns The matching definitions
   */
  public getDefinitions(
    definitionFilters: Filters,
    waypointType: WaypointType,
    tagId?: string
  ): Observable<Definition[]> {
    const cacheKey = `${tagId}-${waypointType}-${JSON.stringify(definitionFilters.toApi())}`;

    const definitionsCache: Definition[] = this.getDefinitionsByTagsCache[cacheKey];

    if (definitionsCache) {
      return of(definitionsCache);
    }

    return this.client.getDefinitions(definitionFilters, tagId).pipe(
      map(definitions =>
        definitions.filter(definition => {
          if (definition.origin) {
            return getWaypointType(definition.origin?.type) === waypointType;
          }

          return getWaypointType(definition?.destination?.type) === waypointType;
        })
      ),
      tap(definitions => {
        this.getDefinitionsByTagsCache[cacheKey] = definitions;
      })
    );
  }

  /**
   * Get similar definitions from a definition
   *
   * @param definition The definition
   * @param gender The user gender
   * @returns The similar definitions
   */
  public similarDefinitions(definition: Definition, gender: Gender): Observable<Definition[]> {
    return this.client.similarDefinitions(definition, gender);
  }

  /**
   * Retrieve tags for an application
   */
  public getTags(waypointType?: WaypointType): Observable<Tag[]> {
    if (this.tagsCache && this.tagsType === waypointType) return of(this.tagsCache);

    return this.tagClient.tags(waypointType).pipe(
      filter(tags => !!tags),
      tap(tags => {
        this.tagsCache = tags;
        this.tagsType = waypointType;
      })
    );
  }

  /**
   * Build an observable emitting a list of object associating a tag name with the observable to get definitions related to the tag
   *
   * @param definitionFilters Filters
   * @param waypointType The waypoint type
   */
  public definitionsByTag(
    definitionFilters: Filters,
    waypointType: WaypointType
  ): Observable<Array<DefinitionByTag>> {
    // Get available tags
    const tags$: Observable<Tag[]> = this.getTags(waypointType);

    return tags$.pipe(
      switchMap(tags =>
        forkJoin([
          ...tags.map(tag =>
            this.getDefinitions(definitionFilters, waypointType, tag.id).pipe(
              map(definitions => ({
                tagLabel: tag.label,
                definitions
              }))
            )
          ),
          // Add definitions without tag in last position in the 'others' tag
          this.getDefinitions(definitionFilters, waypointType).pipe(
            map(definitions => ({ tagLabel: 'others', definitions }))
          )
        ])
      ),
      map(listOfDefinitionsByTag =>
        listOfDefinitionsByTag.filter(definitionsByTag => definitionsByTag.definitions.length)
      )
    );
  }

  /**
   * Build an observable emitting whether definitions of the provided type exists
   *
   * @param definitionFilters Filters
   * @param waypointType The waypoint type
   */
  public hasDefinitionsOfType(
    definitionFilters: Filters,
    waypointType: WaypointType
  ): Observable<boolean> {
    // Check if there is tags only (FAST)
    return this.getTags(waypointType).pipe(map(results => results.length > 0));

    // Check if there is tags with results (SLOW)
    // return this.definitionsByTag(definitionFilters, waypointType).pipe(
    //   map(listOfDefinitionsByTag => !!listOfDefinitionsByTag.length)
    // );
  }
}
