import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Application } from '@clients/adventure/bean/application';
import { AppRouteUrls } from '@core/routing/app/route-urls';
import { ApplicationClient } from '@core/services';
import { ApplicationName } from '@models/context/application-name';
import { Product } from '@models/context/product';
import { ErrorCodes } from '@models/error-codes/error-codes';
import { captureMessage } from '@sentry/angular';
import { environment } from 'environments/environment';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApplicationContext {
  public readonly inNativeApp: boolean;

  private readonly NATIVE_APP_IFRAME_NAME = 'native-app';
  private _application: Application = null;
  private readonly _product: Product = null;
  private readonly _applicationName: string = null;

  public get applicationName(): string {
    return this._application?.application || this._applicationName;
  }

  public get application(): Application {
    return this._application;
  }

  public get product(): Product {
    return this._product;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private applicationClient: ApplicationClient,
    private router: Router
  ) {
    this.inNativeApp = this.document.defaultView.name === this.NATIVE_APP_IFRAME_NAME;

    [this._applicationName, this._product] = this.resolveContextFromHost();
  }

  /**
   * Initialise the context by loading the application associated to the current subdomain
   * If the application is not found redirect to the My Road 404 error page
   */
  public init(): Observable<void> {
    if (!this.applicationName) return this.applicationNotFound('Application name is null');

    if (this.applicationName === ApplicationName.Dispatch) return this.handleDispatch();

    return this.applicationClient.getApplication(this.applicationName).pipe(
      switchMap((application: Application) => {
        if (application) {
          this._application = application;

          return of(null);
        }

        return this.applicationNotFound('Returned application is null');
      }),
      catchError((err: unknown) => {
        if (err instanceof HttpErrorResponse && err.status === 422)
          return this.applicationNotFound(err.message);

        captureMessage((err as Error).message);

        return of(null);
      })
    );
  }

  /**
   * Get the application name from the host
   *
   * @returns The application name
   */
  private resolveContextFromHost(): [string, Product] {
    switch (this.document.defaultView.location.host) {
      case 'https://myroad.letudiant.fr/':
        return [ApplicationName.MyRoad, Product.Explore];
      case environment.hostSuffix[ApplicationName.MyRoad]:
        return [ApplicationName.MyRoad, Product.Explore];
      // case environment.hostSuffix[ApplicationName.CmqBtpNumerique]:
      //   return [ApplicationName.CmqBtpNumerique, Product.Explore];
      default:
        if (this.document.defaultView.location.host.includes('myroad.app')) {
          const match: string[] =
            this.document.defaultView.location.host.match(/((?:\w|-)+)\.myroad.app/);

          return [match[1], Product.Connect];
        }

        return Object.values(Product).reduce(
          (context, product) => {
            if (context[0] && context[1]) {
              return context;
            }

            const match: string[] = this.document.defaultView.location.host.match(
              new RegExp(`((?:\\w|-)+)\\.${environment.hostSuffix[product]}`)
            );

            return match ? [match[1], product] : context;
          },
          [null, null]
        );
    }
  }

  /**
   * Handle dispatch route used as a transition between authentication and redirect to the proper application
   * If the domain is not defined, returns to My Road 404 error page
   *
   * @returns An observable emitting when the dispatch route has been handled
   */
  private handleDispatch(): Observable<void> {
    const url = new URL(this.document.defaultView.location.href);

    if (!url.searchParams.get('domain'))
      return this.applicationNotFound('Domain is missing in dispatch URL');

    // Redirect to given domain, leaving other params
    url.hostname = url.hostname.replace('dispatch', url.searchParams.get('domain'));
    url.searchParams.delete('domain');

    this.document.defaultView.location.href = url.href;

    return of(null);
  }

  /**
   * Redirects to the My Road 404 error page
   *
   * @param message An error message
   * @returns An observable emitting when the navigation is done
   */
  private applicationNotFound(message: string): Observable<void> {
    captureMessage(
      `[${this.applicationName}] Application not found: ${this.document.defaultView.location.href} - ${message}`
    );

    return from(this.router.navigate([`${AppRouteUrls.ERROR(ErrorCodes.ERROR_404)}`])).pipe(
      map(() => null)
    );
  }
}
