import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';
import {
  ApplicationGuard,
  ConnectHomeToTimelineGuard,
  PopupGuard,
  SSOAuthGuard
} from '@core/guards';
import { DiplomaTecthResolver } from '@core/resolvers/diploma-tech.resolver';
import { AdminRouteUrls } from '@core/routing/admin/route-urls';
import { AppRouteUrls } from '@core/routing/app/route-urls';
import { THEMATICS_ROUTES } from '@features/thematics/thematics.routes';
import { ErrorGuard } from '@guards/error.guard';
import { SituationRedirectionGuard } from '@guards/situation-redirection.guard';
import { ApplicationName } from '@models/context/application-name';
import { Product } from '@models/context/product';
import { ErrorCodes } from '@models/error-codes/error-codes';
import { SituationStepGuard } from '@pages/situation-step/situation-step.guard';
import { FeatureSettingsGuard } from './store/settings/feature-settings.guard';

export const HOME_ROUTE: Route = {
  path: '',
  loadChildren: () => import('@pages/home/home.module').then(m => m.HomePageModule),
  canActivate: [PopupGuard, SSOAuthGuard, ConnectHomeToTimelineGuard],
  pathMatch: 'full'
};

export const ADMIN_MODULE_ROUTE: Route = {
  path: AdminRouteUrls.ADMIN,
  loadChildren: () => import('@features/admin/admin.module').then(m => m.AdminModule),
  data: { forbiddenProducts: [Product.Show] },
  canActivate: [ApplicationGuard]
};

export const SCHOOL_ROUTE: Route = {
  path: AppRouteUrls.SCHOOL,
  loadChildren: () => import('@pages/school-page/school-page.module').then(m => m.SchoolPageModule),
  data: {
    forbiddenApplications: [
      ApplicationName.Popup,
      ApplicationName.MyRoad,
      ApplicationName.PaysDeLaLoire,
      ApplicationName.RegionSud,
      ApplicationName.Carto
    ],
    forbiddenProducts: [Product.Connect]
  },
  canActivate: [ApplicationGuard]
};

export const ACCOUNT_ROUTE: Route = {
  path: AppRouteUrls.PROFILE,
  loadChildren: () => import('@pages/account/account.module').then(m => m.AccountPageModule),
  data: { forbiddenApplications: [ApplicationName.Popup], forbiddenProducts: [Product.Show] },
  canActivate: [ApplicationGuard]
};

export const SITUATION_ROUTE: Route = {
  path: AppRouteUrls.SITUATION,
  loadChildren: () => import('@pages/situation/situation.module').then(m => m.SituationPageModule),
  data: { forbiddenApplications: [ApplicationName.Popup] },
  canActivate: [ApplicationGuard],
  resolve: { diplomaTech: DiplomaTecthResolver }
};

export const ROADMAPS_ROUTE: Route = {
  path: AppRouteUrls.ROADMAPS,
  loadChildren: () => import('@pages/roadmaps/roadmaps.module').then(m => m.RoadmapsPageModule),
  data: { forbiddenProducts: [Product.Show] },
  canActivate: [ApplicationGuard]
};

export const EXPEDITION_ROUTE: Route = {
  path: 'expeditions',
  loadChildren: () =>
    import('@features/expeditions/pages/instances/expedition.module').then(
      m => m.ExpeditionPageModule
    ),
  data: { forbiddenProducts: [Product.Show] },
  canActivate: [ApplicationGuard, FeatureSettingsGuard]
};

export const INTENTIONS_ROUTE: Route = {
  path: AppRouteUrls.INTENTIONS,
  loadChildren: () =>
    import('@features/intentions/intentions.module').then(m => m.IntentionsPageModule),
  data: { forbiddenApplications: [ApplicationName.Popup] },
  canActivate: [ApplicationGuard, SituationRedirectionGuard]
};

export const HUMANS_ROUTE: Route = {
  path: AppRouteUrls.HUMANS,
  loadChildren: () =>
    import('@features/humans/humans.module').then(m => m.HumansModule)
};

// NOTE : The adventure route is used to handle legacy redirection from letudiant.fr and the addon
export const ADVENTURE_ROUTE: Route = {
  path: AppRouteUrls.ADVENTURE,
  pathMatch: 'full',
  redirectTo: AppRouteUrls.INTENTIONS
};

export const ADVENTURE_MAP_ROUTE: Route = {
  path: AppRouteUrls.ADVENTURE_MAP(':id'),
  loadChildren: () =>
    import('@pages/adventure/adventure-map/adventure-map.module').then(
      m => m.AdventureMapPageModule
    ),
  canActivate: [ApplicationGuard]
};

export const ADVENTURE_ROADMAP_ROUTE: Route = {
  path: AppRouteUrls.ADVENTURE_ROADMAP(':id'),
  loadChildren: () =>
    import('@pages/adventure/adventure-roadmap/adventure-roadmap.module').then(
      m => m.AdventureRoadmapPageModule
    ),
  canActivate: [ApplicationGuard]
};

export const ERROR_ROUTE: Route = {
  path: AppRouteUrls.ERROR(':code'),
  loadChildren: () => import('@pages/error/error.module').then(m => m.ErrorPageModule),
  canActivate: [ErrorGuard]
};

export const SITUATION_STEP: Route = {
  path: AppRouteUrls.SITUATION_STEP,
  loadComponent: () =>
    import('./pages/situation-step/situation-step.component').then(m => m.SituationStepComponent),
  canActivate: [SituationStepGuard]
};

export const CAUSE_DETAIL: Route = {
  path: 'cause/:uuid',
  loadChildren: () => import('@features/causes/causes.module').then(m => m.CausesModule),
  canActivate: [ApplicationGuard]
};

export const EMBED_ROUTE: Route = {
  path: 'embed',
  loadChildren: () => import('@embed/embed.module').then(m => m.EmbedModule),
  canActivate: [ApplicationGuard]
};

const routes: Routes = [
  HOME_ROUTE,
  ADMIN_MODULE_ROUTE,
  SCHOOL_ROUTE,
  ACCOUNT_ROUTE,
  SITUATION_ROUTE,
  ROADMAPS_ROUTE,
  EXPEDITION_ROUTE,
  INTENTIONS_ROUTE,
  HUMANS_ROUTE,
  ADVENTURE_ROUTE,
  ADVENTURE_MAP_ROUTE,
  ADVENTURE_ROADMAP_ROUTE,
  CAUSE_DETAIL,
  EMBED_ROUTE,
  ...THEMATICS_ROUTES,
  SITUATION_STEP,
  ERROR_ROUTE,
  { path: '**', redirectTo: AppRouteUrls.ERROR(ErrorCodes.ERROR_404) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRouting {}
