import { Injectable } from '@angular/core';
import { Waypoint } from '@clients/adventure/bean/waypoint';
import { AuthService, InstanceClient, LoaderService, WaypointService } from '@core/services';
import { SituationContext } from '@core/services/context/situation/situation.context.service';
import { Adventure } from '@models/adventure/adventure';
import { AdventureType } from '@models/adventure-type/adventure-type';
import { Definition } from '@models/definition/definition';
import { Instance } from '@models/instance/instance';
import { WaypointType } from '@models/waypoint/waypoint-type';
import { BehaviorSubject, Observable, concatMap, map, of } from 'rxjs';

export interface CreateAdventureInstanceParams {
  waypoint: Waypoint;
  definition?: Definition;
  isDestination?: boolean;
  originId?: string;
}
export interface DefineAdventureParams extends CreateAdventureInstanceParams {
  situationWaypoint?: Waypoint;
}

@Injectable({ providedIn: 'root' })
export class AdventureService {
  public instance$: Observable<Instance>;

  private _instance$ = new BehaviorSubject<Instance>(null); // TODO : Move to context service

  public get instance(): Instance {
    return this._instance$.value;
  }

  public set instance(instance: Instance) {
    this._instance$.next(instance);
  }

  constructor(
    private auth: AuthService,
    private client: InstanceClient,
    private loading: LoaderService,
    private waypointService: WaypointService,
    private situationContext: SituationContext
  ) {
    this.instance$ = this._instance$.asObservable();
  }

  /* -- INSTANCE -- */

  /**
   * Add a waypoint to the local instance
   * /!\ It is not updated in database, the instance in the service is only for display. It is
   * synchronized on page loading only and instance is database is only updated on expand
   *
   * @param waypoint The waypoint to add to the local instance
   * @param stage The stage of the waypoint
   */
  public pushToInstance(waypoint: Waypoint, stage: number): void {
    if (this.instance.lastWaypoint?.id === waypoint.id) return;

    // Slice the waypoint list to the new current stage
    if (this.instance.waypoints) {
      this._instance$.next({
        ...this.instance,
        waypoints: [...this.instance.waypoints.slice(0, stage), waypoint]
      } as Instance);
    } else {
      this._instance$.next({ ...this.instance, waypoints: [waypoint] } as Instance);
    }
  }

  /**
   * Attach an instance to an email
   *
   * @param instanceId The instance Ids to attach to the email
   * @param email The email to which the instance has to be attached
   */
  public attachInstance(instanceId: string, email: string): Observable<void> {
    return this.client.attachInstance(instanceId, email);
  }

  /**
   * Create an adventure instance
   *
   * @param email The user email
   * @param adventure The adventure to instantiate
   * @param adventureType The optional adventure type
   * @param isDestination The optional parameter
   * @returns An observable emitting the created adventure instance or null if the adventure is incorrect
   */
  public createAdventure(
    adventure: Adventure,
    adventureType?: AdventureType,
    isDestination?: boolean,
  ): Observable<Instance | null> {
    // Create the adventure based on the definition

    if (adventure.definition) {
      return this.createInstanceFromDefinition(adventure.definition, this.auth.email);
    }

    // Create the adventure based on origin or destination
    if (adventure.origin || adventure.destination) {
      return this.client.createInstanceFromWaypoint(
        this.situationContext.situation,
        adventure,
        adventureType,
        isDestination
      );
    }

    return of(null);
  }

  public createAdventureInstance(params: CreateAdventureInstanceParams): Observable<Instance> {
    const { definition, waypoint, isDestination } = params;

    let instance$: Observable<Instance>;
    const { gender, applicationStep } = this.situationContext.situation;

    let originId: string;

    if (params.originId) {
      originId = params.originId;
    } else if (applicationStep) {
      originId = applicationStep.waypoint.id;
    }

    if (gender && originId) {
      instance$ = this.waypointService.getWaypoint(originId, gender).pipe(
        map(situationWaypoint =>
          this.defineAdventure({
            situationWaypoint,
            waypoint,
            isDestination,
            definition
          })
        ),
        concatMap(adventure => this.createAdventure(adventure, undefined, isDestination))
      );
    } else {
      instance$ = this.createAdventure(
        this.defineAdventure({
          waypoint,
          isDestination,
          definition
        }),
        undefined,
        isDestination
      );
    }

    return this.loading.globalObservableLoadingSession(instance$);
  }

  public defineAdventure(params: DefineAdventureParams): Adventure {
    const { definition, waypoint, isDestination, situationWaypoint } = params;

    if (definition && waypoint.type !== WaypointType.job) {
      return { definition };
    }

    const withDestination = situationWaypoint
      ? { origin: situationWaypoint, destination: waypoint }
      : { destination: waypoint };

    return isDestination ? withDestination : { origin: waypoint };
  }

  /**
   * Clone an instance
   *
   * @param instance The instance
   * @returns The created instance
   */
  public cloneInstance(instance: Instance): Observable<Instance> {
    return this.client.cloneInstance(Instance.createFromInstance(instance));
  }

  // -- UNSAVED INSTANCE --

  /**
   * Create an instance from a definition
   *
   * @param definition The definition
   * @param userEmail The userEmail if it exists
   * @returns The created instance
   */
  private createInstanceFromDefinition(
    definition: Definition,
    userEmail?: string
  ): Observable<Instance> {
    return this.client.createInstanceFromInstance(
      new Instance(this.situationContext.situation, definition, userEmail, definition.adventureType)
    );
  }
}
